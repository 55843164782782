<template>
    <div>
        <input hidden type="text" class="form-control" id="Id" v-model="Id">
        <div class="modal fade" id="regionModal" tabindex="-1" aria-labelledby="regionModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="regionModalLabel" class="font-weight-bold">{{ModalTitle}}{{Id}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>
                            
                            <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Kode Region</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="RegionCode" v-model="RegionCode" class="font-weight-bold" />
                                    <label id="errorRegionCode" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Nama Region</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="RegionName" v-model="RegionName" class="font-weight-bold" />
                                    <label id="errorRegionName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Alamat</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="Address" v-model="Address" class="font-weight-bold" />
                                    <label id="errorAddress" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Kota</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="City" v-model="City" class="font-weight-bold" />
                                    <label id="errorCity" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Provinsi</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="Province" v-model="Province" class="font-weight-bold" />
                                    <label id="errorProvince" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Kode Post</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="PostalCode" v-model="PostalCode" class="font-weight-bold" />
                                    <label id="errorPostalCode" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold">Keterangan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="Notes" v-model="Notes" class="font-weight-bold" />
                                    <label id="errorProvince" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <br>
                            <label id="errorRegionGeneral" class="form-error" style="display: none; color: red;"></label>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import regionService from '../Script/RegionService.js';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { response } from '../../../../infrastructure/constant/response';


export default {
    name: 'RegionForm',
    props: ['reload'],
    components: {
        'date-picker': datePicker
    },
    data: function () {
        var today = new Date()
        return {
            ModalTitle : '',

            Id:'',
            RegionCode: '',
            RegionName: '',
            Address:'',
            City: '',
            Province: '',
            PostalCode: '',
            Notes: '',

            Error: 0,
            SaveType: '',
        }
    },
    methods: {
        addClick(){  
            $(".form-error").css("display", "none");
            $(".save-button").show();
            var today = new Date()
            this.ModalTitle = 'Add Region'       
            this.Id = '';
            this.RegionCode = ''
            this.RegionName = '';
            this.Address = '';
            this.City = '';
            this.Province = '';
            this.PostalCode = '',
            this.Notes = '';
            this.SaveType = 'Add';
            window.$('#regionModal').modal('show');
        },
        editClick(regionData, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();

            var today = new Date()
    
            this.ModalTitle = 'Edit Region: '       
            this.Id = regionData.id;
            this.RegionCode = regionData.region_code;
            this.RegionName = regionData.region_name;
            this.Address = regionData.address;
            this.City = regionData.city;
            this.Province = regionData.province;
            this.PostalCode = regionData.postal_code;
            this.Notes = regionData.notes;

            this.SaveType = 'Edit';
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Region: ';
            }

            window.$('#regionModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.RegionCode == ''){
                this.errorShow('errorRegionCode');
            }
            if(this.RegionName == ''){
                this.errorShow('errorRegionName');
            }
            if(this.Address == ''){
                this.errorShow('errorAddress');
            }
            if(this.City == ''){
                this.errorShow('errorCity');
            }
            if(this.Province == ''){
                this.errorShow('errorProvince');
            }
            if(this.PostalCode == ''){
                this.errorShow('errorPostalCode');
            }
            if(this.Notes == ''){
                this.errorShow('errorNotes');
            }
        },
        saveClick() {
            this.inputValidation();           
            
            if(this.Error == 0){
                this.$loading(true);
                
                //Add
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const regionData = {
                        region_name:this.RegionName,
                        region_code: this.RegionCode,
                        address:this.Address,
                        city: this.City,
                        province: this.Province,
                        postal_code: this.PostalCode,
                        notes: this.Notes
                    };

                    const variables = {
                        data : regionData
                    };

                    regionService.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#regionModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                //Edit
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const regionData = {
                        region_name:this.RegionName,
                        region_code: this.RegionCode,
                        address:this.Address,
                        city: this.City,
                        province: this.Province,
                        postal_code: this.PostalCode,
                        notes: this.Notes              
                    };

                    const variables = {
                        id : this.Id,
                        data : regionData
                    };

                    regionService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#regionModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>
