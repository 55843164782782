import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class RegionService {
    readQuery(){
        var query = `query{
            GetRegion{
                id
                region_code
                region_name
                address
                city
                province
                postal_code
                notes
                created_at
                last_update
            }
        }`;
        return query;
    }

    async deleteQuery(variables){
        const query = gql`mutation($id:Int!){
            DeleteRegion(Id:$id)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables);
    }

    async addQuery(variables){
        const query = gql`mutation($data:NewMasterRegion!){
            CreateRegion(new:$data)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        const query = gql`mutation($id:Int!,$data:NewMasterRegion!){
            UpdateRegion(Id:$id,new:$data)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}

export default new RegionService();